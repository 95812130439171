@import 'variables';
@import 'mixin';

#right-menu {
    float: right;
    top: 16px;
    position: relative;
    z-index: 999;
    padding: 0;
    list-style: none;
    align-items: center;
    display: flex;
    @include fontSize(13px);

    &>li {
        margin: 0;
        line-height: 1;
        margin-left: 10px;
        display: inline-block;

        &:last-child {
            margin-right: 0;
            position: relative;
        }
    }

    .cart {
        .count {
            display: block;
            position: absolute;
            top: -5px;
            right: -9px;
            background-color: $color-1;
            color: #fff;
            height: 16px;
            width: 16px;
            border-radius: 8px;
            text-align: center;
            line-height: 16px;
            font-size: 9px;
            font-weight: 500;
        }

        .rl-eclipse {
            top: -6px;
        }

        img {
            &.cart-icon {
                display: block;
                height: 29px;
                width: 34px;
                filter: contrast(100) invert(1);
            }
        }
    }

    @include tablet {
        position: absolute;
        top: 21px;
        right: 24px;
        float: none;
    }
}

.promo-bar {
    background: $color-1;
    height: 50px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1300;
    color: white;
    font-weight: bold;
    font-size: $font-size-base * 1.5;
    letter-spacing: 1px;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);

    @include mobile {
        font-size: $font-size-base * 1.15;
    }
}

.promo-bar-underneath {
    @include tablet {
        z-index: 1298;
    }
}

.animation-fadeIn {
    animation: fadeIn;
    animation-duration: 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

.left-menu {
    position: absolute;
    left: 60px;
    top: 6px;
    z-index: 999;
    color: #fff;
    font-size: 32px;
    font-size: 2rem;

    ul {
        list-style: none;
        padding: 0px;

        li {
            display: inline-block;
        }
    }
}

/* Header */
header {
    position: fixed;
    left: 0;
    top: 50px;
    width: 100%;
    padding: 12px 0;
    z-index: 1299;
    border-bottom: 1px solid rgba(255, 255, 255, 0);

    #logo {
        float: left;

        @include tablet {
            float: none;
            width: 100%;
            text-align: center;

            img {
                width: auto;
                height: 54px;

                &.white {
                    height: 56px;
                }
            }
        }
    }

    &.header {
        @include mobile {
            padding: 10px 15px;
        }

        .standard {
            display: none;
        }

        &.sticky {
            @include transition-default;
            border-bottom: 1px solid $border-color-2;
            //background-color: rgba(255, 255, 255, 0.94);
            box-shadow: 0px 7px 15px 2px rgba(0, 0, 0, 0.3);

            @include tablet {
                padding: 10px 15px;
            }

            .white {
                display: none;
            }

            .standard {
                display: inline-block;
            }
        }
    }

    &.header, &.header_in {
        &.dark {
            //background: $color-4  url(../img/pattern_white_10.svg) repeat;
            //background: $color-primary-darker url(../img/pattern_white_10.svg) repeat;
            //background: #340123 url(../img/pattern_white_10.svg) repeat;
            // background: linear-gradient(180deg, rgba(52,1,35,1) 0%, rgba(13,13,14,1) url(../img/pattern_white_10.svg))  ;
            // background-repeat: repeat;
            // background-image: linear-gradient(to bottom, rgba(52,1,35,1) 0%, rgba(13,13,14,1)), url('../img/pattern_white_10.svg');
            // background-image: linear-gradient(to bottom, rgba(13,13,14,1) 0%, rgba(52,1,35,1) 80%), url('../img/pattern_white_10.svg');
            background: url('../img/pattern_white_10.svg') center/174px repeat-x, linear-gradient(to bottom, rgba(13,13,14,1) 15%, rgba(52,1,35,0) 100%);
            transition: none;
            border-bottom: solid 1px rgba(13,13,14,1);

            opacity: 0.98;

            #logo {
                .white {
                    display: block;
                    width: 88px;
                }

                .standard {
                    display: none;
                }
            }

            .cart-icon {
                display: block;
                height: 29px;
                width: 34px;
                filter: contrast(100) invert(1) !important;
            }
        }
    }

    &.header_in {
        padding: 15px 0;
        background-color: #fff;
        position: relative;
        border-bottom: 1px solid $border-color-2;

        &.dark {
            background: url('../img/pattern_white_10.svg') center/174px repeat-x, linear-gradient(to bottom, rgba(13,13,14,1) 15%, rgba(52,1,35,1) 100%);

            #logo {
                .white {
                    display: inline-block;
                }
            }
        }

        &.is_sticky {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
        }


        @include tablet {
            padding: 10px 0;

            #logo a {
                z-index: 9;
                position: relative;
            }
        }
    }

    &.no-promo-bar {
        top: 0 !important;
    }

    a.open_close {
        position: absolute;
        left: 10px;
        top: 17px;
        z-index: 999;
        color: #fff;
        @include fontSize(32px);
        display: none;
        width: 25px;
        height: 25px;

        @include tablet {
            display: block;
        }

        span {
            display: none;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

#right-menu, .main-menu, .left-menu {
    li {
        & > a, & > span {
            color: #fff;
            width: 100%;
            cursor: pointer;
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 16px;

            & > img {
                margin-right: 4px;
                position: relative;
                top: -1px;
            }

            &.login {
                text-indent: -9999px;
                overflow: hidden;

                &:before {
                    content: '\0042';
                }
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

/*-------- 1.3 Main Menu --------*/
/* Menu */
.main-menu {
    position: relative;
    z-index: 9;
    top: 11px;
    float: right;
    @include transition-default;

    ul,
    ul li {
        position: relative;
        margin: 0;
        padding: 0;

        a {
            position: relative;
            margin: 0;
            padding: 0;
            display: block;
            padding: 10px;
            color: #fff;
            font-weight: 600;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

#close_in,
#header_menu {
    display: none;
}


/* All styles for screen size over 992px
================================================== */
@media only screen and (min-width: 992px) {

    .main-menu {
        width: auto;

        .text {
            display: none;
            color: #fff !important;
            font-weight: 600;
            font-size: 0.875rem;
            cursor: pointer;
            padding: 0 8px 10px 8px;
        }

        @include mobile {
            margin-right: -5px;
        }
    }

    .main-menu a {
        white-space: nowrap;
    }

    .main-menu ul li {
        display: inline-block;
    }

    .main-menu ul li.submenu:hover>a:before,
    .main-menu ul li.submenu:hover>a:after {
        bottom: -10px;
        opacity: 0;
    }

    /* Submenu*/
    .main-menu ul ul {
        position: absolute;
        border-top: 2px solid $color-1;
        z-index: 1;
        visibility: hidden;
        left: 3px;
        top: 100%;
        margin: 0;
        display: block;
        padding: 0;
        background: #fff;
        min-width: 210px;
        @include box-shadow (0px 6px 12px 0px rgba(0, 0, 0, 0.175));
        transform: scale(0.4);
        transform-origin: 10% top;
        transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
        @include border-radius(3px);
        opacity: 0;
        @include transition-default (all, 0.2s, ease);

        &:before {
            bottom: 100%;
            left: 12%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $color-1;
            border-width: 7px;
            margin-left: -7px;
        }
    }

    .main-menu ul li:hover>ul {
        padding: 0;
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
        -webkit-transition-duration: 0s, 0.2s, 0.2s;
        transition-duration: 0s, 0.2s, 0.2s
    }

    .main-menu ul ul li {
        display: block;
        height: auto;
        padding: 0;
    }

    .main-menu ul ul li a {
        @include fontSize(13px);
        color: $body-text-color;
        border-bottom: 1px solid #ededed;
        display: block;
        padding: 12px 15px;
        @include transition-default (all, 0.3s, ease);
    }
}

@media only screen and (max-width: 991px) {
    #header_menu {
        display: block;
        text-align: center;
        position: relative;
        padding: 30px 0 25px 0;
        background-color: $color-1;

        a.open_close {
            position: absolute;
            right: 15px;
            top: 0;
            left: inherit;
            color: #fff !important;
        }
    }

    .main-menu ul li a:hover,
    a.show-submenu:hover {
        color: $color-1 !important;
        background-color: #f9f9f9;
        opacity: 1 !important;
    }

    .main-menu ul li {
        border-top: none;
        border-bottom: 1px solid #ededed;
        color: #fff;
    }

    /* Menu mobile first level */
    .main-menu ul li a {
        padding: 15px !important;
    }

    .main-menu li,
    .main-menu a {
        display: block;
        color: #333 !important;
    }

    .main-menu li {
        position: relative;
    }

    .main-menu ul>li {
        padding-bottom: 0;
    }

    .main-menu ul>li i {
        float: right;
    }

    .main-menu>ul>li.submenu:hover>a {
        &:after {
            transform: rotate(180deg);
            color: $color-1;
        }
    }

    .main-menu ul>li.submenu>a:after {
        font-family: 'ElegantIcons';
        @include fontSize(21px);
        content: "\33";
        color: #444;
        line-height: 1;
        float: right;
        display: inline-block;
        @include transition-default;
    }

    /* Menu mobile second level */
    .main-menu ul li.submenu ul {
        border-left: 1px solid #ededed;
        margin: 0 0 10px 25px;
    }

    .main-menu ul li.submenu ul li {
        border: 0;
        @include fontSize(13px);
    }

    /* Menu mobile 3rd level */
    .main-menu ul li.submenu ul ul {
        border-left: none;
        margin: 0 0 0 15px;
    }

    /* Menu mobile left panel */
    .main-menu {
        overflow-y: scroll;
        transform: translateX(-102%);
        top: 0;
        left: 0;
        bottom: 0;
        width: 55%;
        height: 100%;
        position: fixed;
        z-index: 999;
        background-color: #fff;
        -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    }

    .main-menu.show {
        transform: translateX(0);
    }

    .main-menu .show-submenu+ul {
        display: none;
        visibility: hidden;
    }

    .main-menu a.show-submenu+ul.show_normal {
        display: block;
        visibility: visible;
    }

}

body {
    &.is-scrolled {
        &.s-500 {
            #page.home {
                .header {
                    background: url('../img/pattern_white_10.svg') center/174px repeat-x, linear-gradient(to bottom, rgba(13,13,14,1) 15%, rgba(52,1,35,1) 100%);
                }
            }
        }

        &.s-400 {
            #page.partners, #page.about, #page.vendor {
                .header {
                    background: url('../img/pattern_white_10.svg') center/174px repeat-x, linear-gradient(to bottom, rgba(13,13,14,1) 15%, rgba(52,1,35,1) 100%);
                }
            }
        }

        @media (min-width: $small-device-width) {
            &.s-300 {
                #page.home {
                    .header {
                        background: url('../img/pattern_white_10.svg') center/174px repeat-x, linear-gradient(to bottom, rgba(13,13,14,1) 15%, rgba(52,1,35,1) 100%);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .main-menu {
        width: 100%;
        margin: auto;
    }
}

@media (max-width: $medium-device-width) {
    #logo {
        .white {
            width: auto !important;
            margin: auto !important;
        }
    }

    .left-menu {
        .dropdown-menu-option{
            cursor: pointer;
            display: block;
            @include fontSize(13px);
            line-height: 16px;
            font-weight: 600;
            padding-top: 18px;
            transition: all 0.3s ease-in-out;
            color: #fff !important;

            i {
                margin-right: 8px;
            }

            &:hover {
                opacity: .7 !important;
            }
        }
    }

    .main-menu {
        li {
            &.lang {
                img {
                    display: none;
                }

                .text {
                    display: block;
                }
            }
        }
    }
}