@import "../_variables";

.page-content.search-all {
    .section-search {
        padding: 30px 0px;
        border-bottom: solid 1px #e6e6e6;
    }

    .section-results {
        padding: 30px 0px;

        .results-group {
            margin-bottom: 40px;

            h2 {
                font-size: 20px;
                margin-bottom: 25px;
                padding: 15px;
                text-align: center;
            }

            .list-view {
                margin-bottom: 15px;

                .list-item {
                    padding: 20px;

                    .img-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        float: left;

                        width: 150px;
                        height: 150px;
                    }
                    
                    .gray {
                        filter: opacity(0.3);
                    }

                    img {
                        float: left;
                        max-width: 150px;
                        max-height: 150px;
                    }

                    .content {
                        float: left;
                        max-width: calc(100% - 150px);
                        padding: 15px 0px 0px 20px;

                        h3 {
                            font-size: 16px;
                            font-weight: 400;
                        }

                        .description {
                            color: $body-text-color-light;
                        }

                        .out-of-stock {
                            font-weight: 600;
                            padding: 7px 8px 4px 8px;
                            z-index: 9;
                            color: white;
                            font-size: 0.75rem;
                            min-width: 40px;
                            line-height: 1;
                            background-color: black;
                            float: right;
                        }
                    }

                    &::after {
                        content: "";
                        display: block;
                        clear: both;
                    }

                    .highlight {
                        color: #000;
                        font-weight: 600;
                    }

                    &:hover {
                        background: #f7f7f7;
                    }
                }
            }

            .actions {
                text-align: right;

                a.more {
                    text-align: center;
                    display: inline-block;
                    color: $color-primary;
                    font-weight: 600;

                    &:hover {
                        color: $color-secondary;
                    }
                }
            }
        }
    }
}
