//-------------------- Base Colors --------------------//

// https://paletton.com/#uid=5580u0koulFftutk-ptsUhmAmbY

$body-color: 					#fff;
$body-text-color:               #444;
$body-text-color-light:         #666;
$heading-color:                 #222;

//$color-1: 						#882060;
$color-1: 						#86075c;
$color-1-30:    	    		rgba(134,7,92,0.3);

$color-text:                    #444;

$color-primary:                 $color-1;
$color-primary-rgb: 			rgb(134,7,92);
$color-primary-30: 	    		rgba(134,7,92,0.3);
$color-primary-15: 	    		rgba(134,7,92,0.15);
$color-primary-10: 	    		rgba(134,7,92,0.1);

$color-primary-darker:          #4B0033;

$color-secondary:               #e50e39;
$color-secondary-rgb:           rgb(229,14,57);
$color-secondary-30:            rgba(229,14,57, 0.3);
$color-secondary-15:            rgba(229,14,57, 0.15);
$color-secondary-10:            rgba(229,14,57, 0.10);

// $color-2: 						#1f2f6a;
$color-2:                       #3E0E5D;
$color-2:                       #683688;
$color-3: 						#b3bac4;
$color-4: 						#0d0d0e;
$color-5: 						#FFC107;

$color-red-light:               #A9282E;
$color-red:                     #880D13;
$color-red-dark:                #620005;

$color-green:                   #8EC343;
$color-green-2:                 #197700;
$color-green-3:                 #37B616;
$color-green-4:                 #589442;

$color-green-3-rgb:             rgb(55, 182, 22);
$color-green-3-60:              rgba(55, 182, 22, 0.6);
$color-green-3-80:              rgba(55, 182, 22, 0.8);

$color-dark-blue:               #0012ff;

$color-dark-blue-rgb:           rgb(0, 18, 255);

$block-reveal-color-1: 			$color-2;

$border-color-2: 			    #ededed;
$border-color-3: 			    #d2d8dd;
$border-color-4: 			    #ccc;
$border-color-5: 			    #c8c8c8;

//-------------------- Line Height Variables --------------------//
$line-height-base:              1.5;
$line-height-large:           	1.8;
$line-height-none:           	1;

//-------------------- Font Families --------------------//
$font-heading: 					'Montserrat', sans-serif;
$font-main:                     'Montserrat', sans-serif;

//-------------------- Font Sizes --------------------//
$font-size-base:                0.875rem;

//-------------------- Responsive sizes -------------------- //
$extra-large-device-width: 1349px;
$large-device-width: 1199px;
$medium-device-width: 991px;
$small-device-width: 767px;
$extra-small-device-width: 575px;
