@import 'variables';
@import 'mixin';

.how-it-works {
    .container-mobile {
        display: none;
    }

    .brain-ruller-arrow-circle,
    .brain-ruller-arrow-circle {
        display: none;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        position: absolute;
        cursor: pointer;
        z-index: 2;
        top: 40px;

        &:hover {
            background: rgba(200, 200, 200, 0.2);
        }

        .brain-ruller-arrow {
            height: 12px;
            width: 12px;
            margin: 14px 12px;
            display: inline-block;
            position: relative;
        }

        .brain-ruller-arrow.left,
        .brain-ruller-arrow.right {
            border-left: 5px solid $color-1;
            border-bottom: 5px solid $color-1;
        }

        .brain-ruller-arrow.dim-arrow {
            border-left: 5px solid gray;
            border-bottom: 5px solid gray;
        }

        .brain-ruller-arrow.right {
            transform: rotate(225deg);
        }

        .brain-ruller-arrow.left {
            transform: rotate(45deg);
            margin: 14px 15px;
        }
    }

    .brain-ruller-arrow-circle.left {
        left: 55px;
    }

    .brain-ruller-arrow-circle.right {
        right: 55px;
    }

    @media (max-width: $small-device-width) {
        .main_title {
            margin-top: -10px;

            .subtitle {
                font-size: 1.15rem;
                padding: 10px 20px;
            }
        }

        .row.container-desktop {
            display: none;
        }

        .container-mobile {
            display: block;
        }

        .brain-ruller-arrow-circle,
        .brain-ruller-arrow-circle {
            display: block;
        }
    }
}