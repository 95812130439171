.owl-theme.categories_carousel {
    margin-top: 30px;

    .item a {
        background-color: #fff;
        padding: 70px 20px;
        text-align: center;
        @include border-radius(3px);
        position: relative;
        margin-bottom: 25px;

        &:hover {
            @include box-shadow (0 20px 20px -20px rgba(0, 0, 0, 0.25));
        }

        i {
            @include fontSize(70px);
            margin: 0;
            padding: 0;
            display: block;
        }

        h3 {
            @include fontSize(18px);
            margin: 0;
        }

        small {
            color: #444;
        }
    }

    .owl-nav {
        position: absolute;
        top: 35%;
        left: 0;
        width: 100%;
        margin: 0 !important;

        .owl-prev,
        .owl-next {
            width: 46px;
            height: 46px;
            position: absolute;
            top: 0;
            display: block !important;
        }

        .owl-prev {
            left: -48px;
        }

        .owl-next {
            right: -48px;
        }

        .owl-prev i,
        .owl-next i {
            @include fontSize(46px);
            color: #ccc;
            @include transition-default;

            &:hover {
                color: $color-1;
            }
        }

        .owl-prev.disabled i,
        .owl-next.disabled i {
            opacity: 0;

            &:hover {
                color: #ccc;
            }
        }
    }
}

.owl-theme.categories_carousel .item a,
.item_version_2 a {
    display: block;

    span {
        position: absolute;
        right: 15px;
        top: 15px;
        display: inline-block;
        line-height: 37px;
        background-color: #f0f0f0;
        color: #777;
        @include fontSize(12px);
        @include border-radius(50%);
        width: 35px;
        height: 35px;
        text-align: center;
        font-weight: 500;
        z-index: 9;
    }

    figure {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin: 0;

        img {
            width: 100%;
            height: auto;
            @include transition-default;
            @include transform (scale(1.1));
        }

        &.vertical {
            img {
                width: auto;
                height: 100%;
                filter: grayscale(1);
                @include transition-default;
                @include transform (scale(1));
            }
        }
    }

    &:hover figure img {
        @include transform (scale(1));
    }
}