@import '../variables';
@import '../mixin';

.internal {
    margin-top: 40px;

    .menu {
        #navbarResponsive {
            min-height: 400px;

            .nav-item {
                padding: 0px 20px;

                .nav-link {
                    padding: 12px 0px;
                    margin-bottom: 8px;
                    background: #f8f8f8;
                    border-radius: 6px;
                    line-height: 16px;
                    color: $color-primary;
                    height: auto;
                    font-size: 14px;
                    font-weight: 500;

                    .nav-link-text {
                        line-height: 16px;
                    }

                    &:hover {
                        background-color: #eee;

                        .nav-link-text {
                            padding-left: 4px;
                            // color: $color-secondary;
                        }
                    }

                }

                &.active {
                    .nav-link {
                        background: $color-primary;
                        color: #fff;
                    }
                }
            }
        }
    }

    .content {
        padding-top: 24px;

        .card {
            &.dashboard {
                //border: solid 1px #ccc;
                @include box-shadow (0px 0px 20px 0px rgba(0, 0, 0, 0.10));
                border-radius: 5px;

                .card-body {

                    h5 {
                        //color: $color-secondary;
                        color: $body-text-color;
                        font-size: 17px;
                    }
                }

                .card-footer {
                    color: $body-text-color !important;

                    a {
                        color: inherit !important;
                    }
                }
            }
        }

        .subsection {
            margin-top: 40px;

            &:first-child {
                margin-top: 0px;
            }

            & > h3, & > h4,  & > h5 {
                color: $color-primary;
                border-bottom: solid 1px #eee;
                padding-bottom: 8px;
                margin-bottom: 30px;
            }
        }
    }
}

.indifferent-input {
    background-color: lightcyan !important;
}

.profile-forms-wrapper {
    & > div {
        display: grid;
    }
}