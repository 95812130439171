footer {
    min-height: 450px;

    .row.with-cols {
        min-height: 272px;
    }

    .funds {
        text-align: center;
        padding: 10px 20px;
        bottom: 0px;
        width: 100%;
        margin-top: 20px;
        background: rgba(255, 255, 255, 0.25);

        picture {
            display: inline-block;
            margin-left: 15px;
            height: 50px;
            width: auto;

            img {
                display: inline-block;
                height: 50px;
                width: auto;
            }
        }
    }

    @media (max-width: 860px) {
        .funds {
            display: none;
        }

        .row.with-cols {
            min-height: auto;
        }
    }

    @media (max-width: 445px) {
        .landing {
            h1 {
                font-size: 34px;
            }

            p {
                font-size: 24px;
            }
        }

        .funds {
            display: none;
            picture,
            img {
                margin: 0px 7px;
                height: 40px;
            }
        }
    }

    @media (max-width: 365px) {
        .landing {
            h1 {
                font-size: 34px;
            }

            p {
                font-size: 24px;
            }
        }

        .funds {
            min-height: 70px;
            picture,
            img {
                margin: 0px 7px;
                height: 34px;
            }
        }
    }

    @media (max-width: 860px) {
        .funds {
            text-align: center;
        }
    }

    @media (max-width: 445px) {
        .funds {

            picture,
            img {
                margin: 0px 7px;
                height: 40px;
            }
        }
    }

    @media (max-width: 365px) {
        .funds {

            picture,
            img {
                margin: 0px 7px;
                height: 34px;
            }
        }
    }

}