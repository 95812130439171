@import '@app/scss/theme/variables';

.search-box {

}

.type-selector {
	position: absolute;
	right: 0px;
	top: 0px;

	button {
		padding: 0px 14px;
		border-radius: 3px;
		margin-right: 3px;
		display: none;

		img {
		height: 35px;
		}

		.type-tooltip {
		width: 0px;
		position: absolute;
		padding: 0px;
		border-radius: 3px;
		font-size: 11px;
		font-weight: 300;
		background-color: #efefef;
		top: 0px;
		max-height: 100%;
		height: 100%;
		right: 65px;
		overflow: hidden;
		-webkit-transition: width 0.15 ease-in-out;
		-moz-transition: width 0.15s ease-in-out;
		-o-transition: width 0.15s ease-in-out;
		transition: width 0.15s ease-in-out;

		.title  {
			font-weight: 500;
			font-size: 14px;
			color: $color-primary;
		}
		}

		&.food {
			img {
				height: 38px;
			}
		}

		&.active {
			display: block;
		}

		&:focus {
			outline: none;
		}

		&:hover {
			.type-tooltip {
				width: 200px;
				padding: 7px 12px;
			}

			img {
				height: 38px;
				border: solid 1px #ccc;
				border-radius: 3px;
				padding: 2px 6px;
				background-color: #fff;;
			}
		}

		@-webkit-keyframes slide {
			100% { left: 0; }
		}

		@keyframes slide {
			100% { left: 0; }
		}
	}
}

@media (max-width: 990px) {
	type-selector {
        button {
          margin-right: 0px;

          &:hover {
            .type-tooltip {
              	width: 268px;
            }
          }
        }
      }
}

@media (max-width: 445px) {
	.type-selector {
        button {
          margin-right: 0px;

          &:hover {
            .type-tooltip {
              width: calc(100vw - 174px);
            }
          }
        }
      }
}