@import 'variables';

html,
body,
#root,
#page {
    min-height: 100%;
    height: 100%;
}

main {
    min-height: calc(100% - 430px);
}

.spacer {
    display: block;
    margin: 8px auto;
    width: 120px;
    height: 2px;
    background-color: #e1e1e1;

    &::after {
        content: "";
        display: block;
        margin: auto;
        width: 60px;
        height: 2px;
        background-color: $color-1;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-family: $font-heading;
}

.text-bold {
    font-weight: bold;
}

.text-em {
    font-size: 17px;
}

.text-primary {
    color: $color-1 !important;
}

.text-red {
    color: $color-red !important;
}

.pattern {
    &.pattern-bottom {
        border-bottom: solid 1px #eee;
    }

    &.pattern-top {
        border-top: solid 1px #eee;
    }
}

.img-loader {
    position: relative;
    padding-top: 50%;
    height: 100%;
    min-height: inherit;

    &::before {
        content: " ";
        position: absolute;
        top: 40%;
        left: 50%;
        width: 30px;
        height: 30px;
        border: 2px solid $color-1;
        border-color: transparent $color-1 transparent $color-1;
        border-radius: 50%;
        margin-top: -20px;
        animation: loader 1s linear infinite;
    }

    &>img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

@keyframes loader {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.shadow {
    @include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
}

.text-primary {
    color: $color-1 !important;
}

.text-secondary {
    color: $color-secondary !important;
}

#join-who {
    h2 {
        margin-bottom: 30px;
    }
}

.img-cover {
    object-fit: cover;
}

div.sort_select {
    direction: ltr;
}

#non-vendor-partners,
#non-vendor-partners-retain-slider {
    padding: 20px;
    text-align: center;

    a {
        margin: 10px auto;
    }

    img {
        max-height: 80px;
        margin: 0 30px;
    }
}

#non-vendor-partners-retain-slider {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;

    div {
        display: none;

        &.active {
            display: inline;
        }
    }

    &+.dots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 24px 0;

        span {
            margin: 0 10px;
        }

        .dot {
            height: 10px;
            width: 10px;
            border-radius: 7px;
            background-color: $color-3;
        }

        .dot-active {
            height: 16px;
            width: 16px;
            border-radius: 8px;
            background-color: $color-1;
        }
    }
}

.only-bg {
    margin: 20px 0px 5px 0px;
    padding: 5px 20px;
    border: solid 1px $color-red;
    border-radius: 6px;
    color: $color-red-light;

    a {
        position: initial;
        color: $color-red-light;
        font-weight: 500;
    }
}

#ot-sdk-btn-floating {
    display: none !important;
}

.ot-sdk-show-settings.btn_1 {
    background: none;
}

.btn_copy_link {
    background: white !important;
    border: 1px solid $color-green !important;
    color: $color-green !important;
    margin: 0 auto;
    display: block;
}

.copy-link-tooltip {
    background: white;
    color: $color-green;
    border-radius: 3px;
    text-align: center;
    margin-top: 10px;
}

.fade-out {
    animation: fadeOut 4s;
}

.copy-checkmark {
    display: inline-block;
    transform: rotate(45deg);
    height: 20px;
    width: 12px;
    border-bottom: 4px solid $color-green;
    border-right: 4px solid $color-green;
}

.display-none {
    display: none;
}

.fade-in {
    animation: fadeIn ease 1s;
}

.server-image {
    width: 100%;

    &.with-loader {
        &::before {
            content: " ";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 30px;
            margin-top: -15px;
            border: 2px solid $color-1;
            border-color: transparent $color-1 transparent $color-1;
            border-radius: 50%;
            animation: loader 1s linear infinite;
        }
    }

    img {
        border-radius: 5px;
        position: relative;

        @include tablet {
            max-width: 100%;
        }
    }
}

#sign-in {
    &::before {
        padding-top: 1px;
    }
}

#sign-in-dialog {
    max-width: 100% !important;
    padding: 20px 25px;
}

.modal-pick-address {
    .description {
        text-align: center;
        background-color: #fafafa;
        padding: 6px;
        margin-bottom: 20px;
    }
}

#password {
    margin: 0 !important;
    padding-right: 0 !important;
}

.mfp-close {
    font-size: 24px;
    font-family: 'ElegantIcons';
    content: "\4d";
}

.mui-modal-login {
    .MuiDialogContent-root {
        padding: 0 !important;
    }

    .modal_header {
        left: 0 !important;
    }
}

.menu-dropdown {
    &>a {
        &>figure {
            display: block !important;
        }

        &::after {
            top: -4px !important;
        }
    }

    &>div {
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0px;
        transform: translate(-90px, 45px);
    }
}

.blog-post-img-main {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.blog-post-content {
    word-wrap: break-word;
    margin-bottom: 30px;

    figure {
        &.wp-block-image {
            max-width: 100%;
            margin: 10px 0px;
        }

        img {
            margin-top: 20px;
            border-radius: 3px;
            object-fit: cover;
            max-width: 100%;
        }
    }

    img.size-full {
        max-width: 100%;
        height: auto;
    }
}

.aligncenter {
    text-align: center !important;
}

.card.dashboard {
    border-radius: .25rem;

    h5 {
        color: white;
    }

    .item-list {
        margin-top: 20px;

        .item {
            height: 70px;

            figure {
                width: 60px;
                max-width: 60px;
                display: table-cell;
                overflow: hidden;

                img {
                    height: 60px;
                    width: 100%;
                    margin: auto;
                    object-fit: cover;
                }
            }

            .right {
                display: table-cell;
                padding-left: 15px;
                vertical-align: top;
            }
        }
    }
}

.card-body-icon {
    position: absolute;
    z-index: 0;
    top: 10px;
    right: 10px;
    font-size: 32px;
    font-size: 2rem;
    opacity: 0.3;
}

.dropdown-notifications {
    left: -275% !important;
}

.auth-icon {
    font-size: 1.4em;

    div.dropdown-menu {
        transform: translate(-100px, 35px);

        @include tablet {
            transform: translate(-75px, 45px);
        }
    }
}

.success-text,
.success-countdown {
    font-weight: 600;
    color: $color-green-2;
}

.g-recaptcha {
    margin-bottom: 1rem;
}

.svg-location-pin {
    left: 16px;
    position: absolute;
    background-color: #fff;
    top: 11px;
    height: 28px;
}

.svg-text-ping {
    left: 16px;
    position: absolute;
    background-color: #fff;
    top: 11px;
    height: 28px;
}

.svg-price,
.svg-copy,
.svg-settings-alt,
.svg-alarm,
.svg-pay,
.svg-user,
.svg-sign-out,
.svg-sign-in,
.svg-key,
.svg-arrow-right,
.svg-location-arrow {
    height: 16px;
}

.svg-star,
.svg-ui-calendar,
.svg-heart {
    height: 30px;
    position: absolute;
    top: -16px;
    right: -16px;
}

.svg-user {
    height: 22px;
    cursor: pointer;
}

.ei-star,
.ei-caret {
    font-family: 'ElegantIcons';
    font-style: normal;
    cursor: pointer;
    color: #eee;
}

.ei-star {
    font-size: 26px;

    &.checked {
        color: $color-1;
    }
}

.rating-stars-wrapper {
    span {
        margin-left: 6px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.top-menu-auth-options {
    & a {
        font-size: 0.875rem;
        font-weight: 600;
    }
}

.success-countdown {
    color: $color-green-2;
}

.forgotten-password-link {
    color: $color-dark-blue;
}

@media (min-width: ($medium-device-width + 1)) {
    .left-menu {
        display: none;
    }

    .server-image {

        img.cover {
            height: 450px;
            width: 100%;
            object-fit: cover;
        }
    }

    .page-container.product {
        .slide {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: none !important;
        }
    }

    .mui-modal-login {
        .MuiPaper-root {
            width: 35% !important;
        }
    }

    #non-vendor-partners {
        div {
            display: inline;
        }

        img {
            filter: contrast(0%);
            opacity: 0.7;
            transition: all 0.3s linear;
        }

        img:hover {
            filter: contrast(100%);
            opacity: 1;
            transition: all 0.3s linear;
        }
    }

    .top-menu-auth-options {
        display: none;
    }

    .auth-icon {
        .dropdown-menu {
            margin-top: 9px !important;
        }
    }

    .dropdown-menu {
        margin-top: 10px !important;
    }

    .sticky-sidebar {
        position: sticky;
        top: 2rem
    }
}

@media (min-width: $medium-device-width) {
    .products-mobile {
        display: none;
    }

    .view-all-btn {
        margin-top: -45px;
        margin-bottom: 50px;
    }

    #sign-up {
        margin-top: .1em !important;
    }
}

@media (max-width: $medium-device-width) {
    .lang-hide-mobile {
        display: none !important;
    }

    div.sort_select {
        float: right !important;
        padding: 0 15px 0 35px;
    }

    a.open_filters.btn_filters {
        float: left !important;
    }

    #non-vendor-partners {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0 20px;

        div {
            display: none;

            &.active {
                display: inline;
            }
        }
    }

    .dots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 24px 0;

        span {
            margin: 0 10px;
        }

        .dot {
            height: 10px;
            width: 10px;
            border-radius: 7px;
            background-color: $color-3;
        }

        .dot-active {
            height: 16px;
            width: 16px;
            border-radius: 8px;
            background-color: $color-1;
        }
    }

    .products-mobile {
        display: none;
    }

    .view-all-btn {
        margin-top: -20px;
        margin-bottom: 20px;
    }

    .dashboard-sidebar {
        display: none;
    }

    #alertsDropdown {
        &::after {
            display: none;
        }
    }

    #sign-up {
        margin-top: .4em !important;
    }

    .orders-history-title-container {
        text-align: center;
    }

    .top-menu-guest {
        right: 20px !important;
    }
}

@media (max-width: $small-device-width) {
    .products-desktop {
        display: none;
    }

    .products-mobile {
        display: block;
        margin-top: 35px;

        .city {
            padding: 0;

            &>h4 {
                position: relative;
                background: #fff;
                border-bottom: solid 1px #eee;
                font-size: 20px;
                margin: 0 0px 15px 0px;
                padding: 7px 0;
                cursor: pointer;

                i {
                    position: absolute;
                    right: 0;
                    top: 10px;
                }
            }

            &>div {
                background-color: white;

                &>ul {
                    margin: 0;
                    padding: 10px 0 10px 30px;
                }
            }
        }
    }

    .view-all-btn {
        margin-top: 30px;
    }

    .captcha-newsletter,
    .captcha-contact {
        transform: scale(.9);
    }

    .captcha-newsletter {
        margin-left: -14px;
    }

    .captcha-contact {
        margin-left: -15px;
    }
}

@media (max-width: $extra-small-device-width) {
    section.pagination_fg>* {
        padding: 8px 11px;
    }

    .copy-link-tooltip {
        position: fixed;
        z-index: 10;
        bottom: 2%;
        left: 9.9%;
        min-width: 80%;
        margin: 0;
    }

    #sign-in-dialog {
        padding: 25px;
    }
}

@media (max-width: $extra-small-device-width) {
    section.pagination_fg>* {
        padding: 8px 11px;
    }
}

@media (min-width: $large-device-width) and (max-width: $extra-large-device-width) {
    h1 {
        font-size: 2.1em;
    }

    h2 {
        font-size: 1.9em;
    }

    h3 {
        font-size: 1.5em;
    }

    p {
        font-size: 1.1em;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    50% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}

.hours-schema {
    margin-left: 5px;

    dl {
        dt {
            font-weight: 500;
        }

        dd {
            padding-left: 10px;
        }
    }
}


a.box_topic {
    text-align: center;
    background: #fff;
    min-height: 290px;
    padding: 40px 30px 15px 30px;

    @include mobile {
        padding: 20px 20px 0 20px;
    }

    display: block;
    @include transition-default;
    @include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
    color: $body-text-color;
    margin-bottom: 30px;
    overflow: hidden;
    @include border-radius(5px);

    &:hover {
        @include transform (translateY(-10px));
    }

    i {
        font-size: 60px;
        color: $color-1;
        display: block;
        margin: 0 auto 15px;
    }

    img {
        height: 70px;
        margin: 0 auto 35px;
    }

    h3 {
        @include fontSize(24px);
        margin-bottom: 16px;
    }
}

.product-card {
    &.error {
        figure {
            filter: grayscale(1);
        }
    }

    &.popular {
        max-width: 450px;
    }

    .product-error {
        font-size: 13px;
        font-weight: 400;
        margin-top: 15px;
    }

    .props {
        padding-left: 18px;
        margin-top: -1px;

        span {
            margin-right: 8px;

            img {
                margin-top: -1px;
                height: 15px;
                margin-right: 3px;
            }
        }
    }

    .strip figure img {
        height: 190px;
    }

    .strip_info {
        & > * {
            z-index: 10;
        }

        &:after {
            content: "";
            left: 0;
            right: 0;
            z-index: 4;
            bottom: 0;
            top: 0;
            background-image: linear-gradient(180deg,rgba(0,0,0,0.3),rgba(0,0,0,0.4));
            position: absolute;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }
}

.sort_select {
    width: auto;
    overflow: hidden;
    height: 42px;
    line-height: 22px;
    border: none;
    position: relative;
    padding: 0 15px 15px 45px;
    margin-bottom: 15px;
    border: 1px solid $border-color-2;
    @include border-radius(3px);

    @include tablet {
        padding: 0 27px 0 10px;
        max-width: 220px;
        float: left;
        width: inherit;
        @include fontSize(13px);
    }

    &:after {
        content: "\2a";
        font-family: 'ElegantIcons';
        position: absolute;
        color: $body-text-color;
        right: auto;
        left: 10px;
        top: 10px;
        @include fontSize(18px);
    }

    select {
        background: transparent;
        width: 110%;
        border: 0;
        @include box-shadow (none);
        height: 42px;
        margin: 0;
        -moz-appearance: window;
        -webkit-appearance: none;
        cursor: pointer;
        outline: none;
        color: $body-text-color;

        &:focus {
            color: $body-text-color;
            outline: none;
            @include box-shadow (none);
        }

        &::-ms-expand {
            display: none;
        }
    }
}

.filter_type {
    border-bottom: 1px solid $border-color-2;

    &:last-child {
        border-bottom: none;
    }

    label {
        font-weight: 400;

        small {
            float: right;
            color: #777;
            padding-top: 3px;
        }
    }

    ul {
        list-style: none;
        padding: 5px 5px 0 5px;
    }

    h4 {
        a {
            display: block;
            @include fontSize(14px);
            color: $body-text-color;
            cursor: pointer;
            padding: 8px 10px;
            margin: 8px -5px 0 -5px;
            line-height: 1;
            position: relative;

            &:after {
                @include transition-default;
                font-family: 'ElegantIcons';
                @include fontSize(26px);
                position: absolute;
                right: 3px;
                top: 2px;
            }

            &.opened {
                background-color: #f8f8f8;

                &:after {

                    content: "\33";

                    transform: rotate(180deg);
                }

            }

            &.closed {
                &:after {
                    content: "\33";
                    transform: rotate(0);
                }
            }
        }
    }
}


a.btn_filters {
    border: 1px solid $border-color-2;
    display: none;
    padding: 10px;
    @include border-radius(3px);
    height: 42px;

    span {
        display: none;
    }

    i {
        @include fontSize(21px);
        color: $body-text-color;
    }

    @include tablet {
        float: right;
        display: block !important;
        margin: 0 0 0 5px;
        background: none;
    }

    &.has_filters {
        background-color: rgba(136, 32, 96, 0.2);
        border: rgba(136, 32, 96, 0.8);
    }
}

.filter_col {
    border: 1px solid $border-color-2;
    padding: 0px 15px;
    @include border-radius(3px);
    @include border-radius(0);

    @include tablet {
        overflow: auto;
        transform: translateX(-200%);
        top: 0;
        left: -100%;
        bottom: 0;
        width: 45%;
        height: 100%;
        position: fixed !important;
        background-color: #fff;
        z-index: 99999999 !important;
        margin: 0;
        -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);

        &.show {
            transform: translateX(0);
            left: 0;
            border: 0;
        }
    }

    @include mobile {
        width: 100%;
    }

    .inner_bt {
        border-bottom: 1px solid $border-color-2;
        text-align: right;
        margin: -10px -15px 15px -15px;
        padding: 0 8px;
        display: none;

        a.open_filters {
            float: none;
            border: 0;
            padding: 0;
        }

        @include tablet {
            display: block;
        }

        i {
            @include fontSize(36px);
            color: #999;

            &:hover {
                color: #111;
            }
        }

    }

}



/*-------- Page head --------*/
.carousel_1 .item {
    @include border-radius(5px);
    overflow: hidden;
    margin-bottom: 10px;
}

.box_booking {
    border: 1px dotted $border-color-3;
    @include border-radius(5px);
    margin-bottom: 18px;
    background-color: #fff;

    .head {
        text-align: center;
        padding: 23px 20px 18px 20px;
        background-color: #f8f8f8;
        border-bottom: 1px dotted $border-color-3;

        h3 {
            @include fontSize(24px);
            margin: 0;
        }

        small {
            display: block;
        }

        .offer {
            color: $color-secondary;
            font-weight: 500;
            @include fontSize(16px);
        }

    }

    .main {
        padding: 18px;

        #datepicker_field {
            display: none;
        }

        h5 {
            padding-bottom: 20px;
            border-bottom: 1px solid #ededed;
        }
    }

    .form-group {
        margin: 0 0 10px 0;

        input,
        textarea {
            @include border-radius(0);
            border: 1px dotted #ccc;
        }
    }

    textarea {
        height: 130px;
    }

    a.close_panel_mobile {
        position: absolute;
        right: 10px;
        top: 0;
        color: #333;
        @include fontSize(26px);
        display: none;
    }

    .opt_order {
        border-bottom: 1px solid #ededed;
        margin-bottom: 20px;
        padding: 15px 0 5px 0;
    }

    &.mobile_fixed {
        @include mobile {
            display: none;
            @include border-radius(0);
            position: fixed;
            overflow-y: scroll;
            border: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 999999;
            background-color: #fff;

            .head {
                padding: 10px 20px 10px 20px;
                position: relative;

                h3 {
                    @include fontSize(18px);
                    text-transform: uppercase;
                }

                .offer {
                    display: none;
                }

                a.close_panel_mobile {
                    display: inline-block;
                }
            }

            .main {
                padding-bottom: 50px;
            }

            .btn_1_mobile {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 5px;
                background-color: #fff;
                border-top: 1px solid #ededed;
                z-index: 9999999;

                .text-center {
                    display: none;
                }
            }
        }
    }
}

/*-------- Fixed Booking Panel--------*/
.box_booking {

    a.close_panel_mobile {
        position: absolute;
        right: 10px;
        top: 0;
        color: #333;
        @include fontSize(26px);
        display: none;
    }

    &.mobile_fixed {
        @include mobile {
            display: none;
            @include border-radius(0);
            position: fixed;
            overflow-y: scroll;
            border: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 999999;
            background-color: #fff;

            .head {
                padding: 10px 20px 10px 20px;
                position: relative;

                h3 {
                    @include fontSize(18px);
                    text-transform: uppercase;
                }

                .offer {
                    display: none;
                }

                a.close_panel_mobile {
                    display: inline-block;
                }
            }

            .main {
                padding-bottom: 50px;
            }

            .btn_1_mobile {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 10px;
                background-color: #fff;
                border-top: 1px solid #ededed;
                z-index: 9999999;

                .text-center {
                    display: none;
                }
            }
        }
    }
}

.btn_reserve_fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ededed;
    display: none;
    z-index: 9;

    @include mobile {
        display: block;
    }
}


.tabs_detail {
    margin-top: 15px;

    .nav-tabs {
        border: none;
        border-bottom: 2px solid #ededed;
        display: flex;

        .nav-item {
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .tab-content {
        .card {
            border: none;
            background-color: transparent;

            .card-header {
                padding: 0;
                background-color: transparent;
                border: 0;
                background-color: #f8f8f8;

                a {
                    display: block;
                    @include fontSize(18px);
                    padding: 15px;
                    color: $heading-color;
                }
            }

            .card-body {
                padding: 45px 0 25px 0;

                @include tablet {
                    padding: 25px 0 5px 0;
                }

                &.reviews .add_bottom_45 {
                    @include tablet {
                        padding-bottom: 25px;
                    }
                }
            }
        }
    }

    /*
    @media(min-width:768px) {

        .tab-content .card {
            border: none;
        }

        .tab-content .card .card-header {
            display: none;
        }

        .tab-content .card .collapse {
            display: block;
        }
    }


    @media(max-width:767px) {
        .tab-content {
            margin-bottom: 25px;

            .card-header {
                a {
                    &:after {
                        font-family: 'ElegantIcons';
                        width: 20px;
                        display: block;
                        float: right;
                    }

                    &.collapsed {
                        &:after {
                            content: "\50";
                        }
                    }

                    &:after {
                        content: "\4f";
                    }
                }
            }
        }

        .tab-content .fade:not(.show) {
            opacity: 1;
        }

        .tab-content .tab-pane {
            display: block !important;
            opacity: 1;
        }
    }*/
}


ul.share-buttons {
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0 0 25px 0;

    li {
        display: inline-block;
        margin: 0 5px 5px 5px;

        a {
            background: #fff;
            border: 1px solid $border-color-2;
            @include border-radius(3px);
            font-weight: 500;
            @include fontSize(13px);
            padding: 7px 20px;
            transition: 0.3s;
            display: inline-block;
            line-height: 17px;
            font-weight: 500;

            &:hover {
                color: #fff;
            }

            &.fb-share {
                color: #3b5998;

                &:hover {
                    background: #3b5998;
                    color: #fff;
                    border-color: #3b5998;
                }
            }

            &.gplus-share {
                color: #dd4b39;

                &:hover {
                    background: #dd4b39;
                    color: #fff;
                    border-color: #dd4b39;
                }
            }

            &.twitter-share {
                color: #1da1f2;

                &:hover {
                    background: #1da1f2;
                    color: #fff;
                    border-color: #1da1f2;
                }
            }
        }

        i {
            @include fontSize(16px);
            position: relative;
            right: 3px;
            top: 2px;
        }
    }
}

.review_card {
    border: 1px solid $border-color-2;
    @include border-radius(3px);
    padding: 25px 25px 10px 25px;
    margin-bottom: 30px;
    @include box-shadow (0 2px 4px rgba(3, 27, 78, .06));

    .user_info {
        text-align: center;

        @include mobile {
            margin-bottom: 10px;
        }

        figure {
            width: 50px;
            height: 50px;
            overflow: hidden;
            @include border-radius(50%);
            display: inline-block;
            position: relative;
            margin-bottom: 10px;

            @include mobile {
                float: left;
            }

            img {
                width: 50px;
                height: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                @include transform (translate(-50%, -50%));
            }
        }

        h5 {
            @include fontSize(13px);

            @include mobile {
                float: left;
                margin: 18px 0 0 15px;
            }
        }
    }

    .review_content {
        h4 {
            @include fontSize(18px);
        }

        .rating {
            float: left;
            font-weight: 600;
            letter-spacing: 2px;
            color: $color-1 !important;
            @include fontSize(26px);

            small {
                @include fontSize(12px);
                font-weight: 600;
            }

            strong {
                @include fontSize(13px);
                font-weight: 400;
                color: #444;
            }

            @include extra-small-mobile {
                float: none;
                display: block;
            }

            em {
                @include extra-small-mobile {
                    float: none;
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }

        em {
            color: #999;
            float: right;

            @include extra-small-mobile {
                float: none;
                display: block;
                margin-top: 10px;
                margin-left: 0;
            }
        }

        ul {
            margin: 35px 0 25px 0;
            padding: 0;

            li {
                display: inline-block;
                @include fontSize(13px);
                margin-right: 0;

                span {
                    @include extra-small-mobile {
                        display: none;
                    }
                }

                a {
                    background-color: #f0f0f0;
                    padding: 7px 10px 5px 10px;
                    @include border-radius(3px);
                    color: $body-text-color;

                    &:hover {
                        background-color: $color-1;
                        color: #fff;
                    }

                    i {
                        margin-right: 5px;

                        @include extra-small-mobile {
                            margin-right: 0;
                        }
                    }
                }

                &:last-child {
                    float: right;
                    margin-right: 0;
                }
            }
        }
    }

    .reply {
        padding-top: 30px;

        .user_info {
            @include mobile {
                display: none;
            }

            figure {
                width: 50px;
                height: 50px;
                overflow: hidden;
                @include border-radius(50%);
                display: inline-block;
                position: relative;
                margin-bottom: 10px;

                img {
                    width: 50px;
                    height: auto;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    @include transform (translate(-50%, -50%));
                }
            }

            h5 {
                @include fontSize(14px);
            }
        }

        .review_content {
            border-left: 5px solid #ededed;
            padding-left: 20px;

            strong {
                @include extra-small-mobile {
                    display: block;
                }
            }

            em {
                color: #999;
                font-style: italic;
                float: right;

                @include extra-small-mobile {
                    float: none;
                    display: block;
                    margin-top: 10px;
                }
            }

        }
    }
}

.other_info {
    background-color: #f9f9f9;
    border: 1px dotted $border-color-3;
    padding: 25px 25px 5px 25px;
    margin-bottom: 20px;

    h2 {
        margin-bottom: 35px;
    }
}

.info_content {
    h2 {
        text-align: center;
        font-weight: 500;
        margin-bottom: 20px;
        @include fontSize(24px);
    }

    h3 {
        font-weight: 500;
        margin-bottom: 15px;
        @include fontSize(18px);
    }

    hr {
        margin-top: 25px;
        margin-bottom: 25px;
        ;
    }

    .menu_item {
        h4 {
            font-weight: 500;
            margin-bottom: 0;
            @include fontSize(14px);
        }

        em {
            float: right;
        }

        p {
            color: #777;
        }
    }
}

.change_personal_info {
    box-shadow: none !important;
}

.box_booking_2, .sign_up {
    @include border-radius(5px);
    margin-bottom: 25px;
    background-color: #fff;
    @include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.3));
    overflow: hidden;
    .head {
        text-align: center;
        padding: 23px 20px 18px 20px;
        background-color: $color-1;
        color:#fff;
        h3 {
            @include fontSize(24px);
            margin: 0;
            color:#fff;
        }

        small {
            display: block;
        }

        a {
            color: #fff;
           text-decoration: underline;
        }
    }
    .main {
        padding: 18px;
        ul {
         list-style: none;
         padding: 0;
         li {
          font-weight: 500;
          margin-bottom: 5px;
          span {
            float: right;
            font-weight: 400;
          }
          &.total{
            @include fontSize(16px);
            font-weight: 600;
            color: $color-secondary;
            text-transform: uppercase;
            span {
              font-weight: 600;
            }
           }
        }
      }
    }
    .form-group {
    input {
      padding-right: 40px;
    }
    i {
      @include fontSize(16px);
      position: absolute;
      right: 3px;
      top: 12px;
      color: #ccc;
      width: 25px;
      height: 25px;
      display: block;
      font-weight: 400!important;
       &.icon_mail {
        top:10px;
      }
    }
  }
}

.box_booking_2.style_2 {
    .head {
        text-align: left;
        padding: 15px 20px 12px 25px;
         h3 {
            @include fontSize(21px);
        }
    }
    .main {
       padding: 20px 25px 10px 25px;
    }
    .form-group {
    input {
      padding-right: 10px;
    }
  }
}

.custom_select {
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid #ededed;
    padding: 8px;

    .value {
        margin-left: 25px;
    }

    .options {
        position: absolute;
        top: 38px;
        width: 95%;
        z-index: 10;
        border: solid 1px #ccc;

        .option {
            padding: 8px 10px;
            border-bottom: 1px solid #ccc;
            background-color: #fff;
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: #fafafa;
            }
        }
    }

    &::after {
        color: #444;
        content: "\2a";
        font-family: ElegantIcons;
        font-size: 1.125rem;
        left: 10px;
        position: absolute;
        right: auto;
        top: 5px;
    }
}

.form-control {
    &.error {
        border-color: #A9282E;
        border-width: 2px;
    }
}

.checkout-box {
    .main {
        padding: 0px;

        .cart-empty {
            text-align: center;
            margin: 20px 20px 40px;
        }

        .submit {
            margin: 0px 16px;

            button {
                background-color: $color-secondary;
                border-color: $color-secondary;
                height: 42px;
                font-size: 1.2em;

                &[disabled] {
                    background-color: #d7d7d7;
                    border: none;
                    color: #fff;
                }
            }
        }
    }

    .error-message {
        white-space: pre-wrap;
        text-align: center;
        padding: 0px 12px;
    }

    .vendor-products {
        h4 {
            margin-bottom: 7px;
            font-size: 18px;
            padding: 10px;
            border-bottom: solid 1px #eee;
            border-top: solid 1px #eee;
            text-align: center;
            color: #fff;
            background-color: #f8f8f8;

            a {
                color: $color-primary;
            }
        }

        .carryout-details {
            text-align: center;

            .hours {
                .hour {
                    color: $color-1;
                    font-weight: 600;
                }
            }
        }

        .carryout-single-option {
            font-weight: 400;
            font-size: 0.9em;
            margin: 3px 15px;
            text-align: center;
        }

        .delivery {
            text-align: center;
            margin: 14px 0px 10px 0px;

            .take, .deliv {
                border: solid 1px $color-primary;
                padding: 7px 14px;
                margin: 0px;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version */

                &.take {
                    border-right: none;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;

                }

                &.deliv {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                &.yes {
                    background: $color-primary;
                    color: #fff;
                }

                &.no {
                    text-decoration: none;
                }

                &::before {
                    content: none;
                }
            }
        }

        .products {
            margin: 16px;
        }

        table {
            width: 100%;

            tr {
                border-bottom: solid 1px #f3f3f3;

                &:last-child {
                    border-bottom: none;
                }
            }

            .product {

                img {
                    height: 50px;
                    width: 50px;
                }

                td {
                    vertical-align: top;
                    padding: 5px;

                    &.actions {
                        //vertical-align: middle;
                        padding-right: 8px;

                        i {
                            cursor: pointer;

                            &::before {
                                font-size: 17px;
                                color: #999;
                            }

                            &:hover {
                                &::before {
                                    color: $color-1;
                                }
                            }
                        }
                    }

                    &.description {
                        padding-left: 10px;
                        padding-right: 10px;

                        .count {
                            font-weight: 500;
                        }
                    }

                    &.total {
                        min-width: 80px;
                        text-align: right;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .coupons {
        text-align: center;

        .input-container {
            width: 60%;
            margin: auto;
            display: flex;
            flex-direction: row;

            button {
                padding: 0px 10px;
                height: 32px;
            }

            input {
                height: 32px;
            }
        }

        .add-button {
            font-size: 13px;
            cursor: pointer;
        }
    }

    .summary {
        margin: 0px 20px 15px 20px;
        text-align: right;

        &.with-delivery {
            .total {
                margin-top: 6px;
            }
        }

        .coupon {
            i {
                cursor: pointer;
                margin-right: 5px;
            }

            .title {
                color: $color-1;
                font-weight: bold;
                font-size: 12px;
            }
        }

        .number {
            font-weight: 500;
            min-width: 80px;
            text-align: right;
            display: inline-block;
        }
    }
}

.past-order {
    figure {
        margin-bottom: 0px;
    }

    .card-footer {
        text-align: right;

        & > span {
            margin-left: 20px;
            color: #ccc;

            &.total {
                font-weight: 500;
                color: $body-text-color;
            }
        }
    }

    .order-items {
        width: 100%;

        td {
            padding: 5px;

            &.quantity, &.total {
                width: 80px;
                text-align: right;
            }
        }
    }
}

figure {
	.item_title {
		padding-left: 42px !important;
	}
}

.pac-container {
    z-index: 10000;
}